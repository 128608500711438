<template>
    <div class="component-container" :style="{ background: cmsBlock.backgroundColor }">
        <div class="split-columns">
            <div class="item" :class="{ odd: cmsBlock.contentLeft }">

                <div class="img" :class="{ clip: cmsBlock.clipImage }">
                    <picture>
                        <template v-for="[format, set] of Object.entries(srcsets)">
                            <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 100vw, 50vw"
                                :type="'image/' + format.slice(1)">
                        </template>
                        <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                            :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 100vw, 50vw"
                            :alt="cmsBlock.Image.data.attributes.alternativeText">
                    </picture>
                </div>

                <div class="content" :class="{ white_typo: cmsBlock.whiteTextColor }">
                    <h3 class="title">{{ localizeAlt(cmsBlock.TextContent, 'title') }}</h3>
                    <div class="content-text">{{ localizeAlt(cmsBlock.TextContent, 'text') }} </div>
                    <a v-if="localizeAlt(cmsBlock.Button, 'title') && !isInternalLink(localizeAlt(cmsBlock.Button, 'link'))"
                        class="link" :href="localizeAlt(cmsBlock.Button, 'link')">{{ localizeAlt(cmsBlock.Button, 'title')
                        }}</a>
                    <router-link
                        v-if="localizeAlt(cmsBlock.Button, 'title') && isInternalLink(localizeAlt(cmsBlock.Button, 'link'))"
                        class="link" :to="localizeAlt(cmsBlock.Button, 'link')">{{ localizeAlt(cmsBlock.Button, 'title')
                        }}</router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SplitColumns',

    props: [
        'cmsBlock'
    ],

    computed: {
        srcsets() {
            return this.getImageSrcsets(this.cmsBlock.Image.data.attributes)
        },

        hasWebp() {
            return this.srcsets['.webp'] ? true : false
        },

        originalFormat() {
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== '.webp') {
                    return format
                }
            }
            return null
        },


    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .split-columns {
        color: $timberGreen;
        max-width: $container-width;
        margin: 0 auto;

        .white_typo {
            .content-text {
                color: white;
            }

            h3 {
                color: white;
            }

            a {
                border-color: white;
                color: white;
            }
        }

        .item {
            width: 100%;
            display: flex;
            max-width: unset;
            padding: unset;

            @include breakpoint('mobile') {
                flex-direction: column;
                height: unset;
            }


            .img {
                width: 48%;
                max-height: 340px;
                position: relative;

                @include breakpoint('mobile') {
                    max-height: unset;
                    width: calc(100% - 48px);
                    margin-right: 24px;
                    margin-left: 24px;
                }

                img {
                    object-fit: cover;
                    object-position: 50% 50%;
                    width: 100%;
                    height: 100%;
                }

                &.clip {
                    clip-path: polygon(0% 0%, 100% 0%, calc(100% - 96px) 100%, 0% 100%);

                    @include breakpoint('mobile') {
                        max-height: 200px;
                        width: calc(100% - 40px);
                        margin-right: 40px;
                        margin-left: unset;
                        clip-path: polygon(0% 0%, 100% 0%, calc(100% - 62px) 100%, 0% 100%);
                    }
                }
            }

            &.odd {
                .img {
                    order: 1;

                    @include breakpoint('mobile') {
                        order: 0;
                        max-height: unset;
                        width: calc(100% - 48px);
                        margin-right: 24px;
                        margin-left: 24px;
                    }

                    &.clip {
                        clip-path: polygon(96px 0%, 100% 0%, 100% 100%, 0% 100%);

                        @include breakpoint('mobile') {
                            order: 0;
                            max-height: 200px;
                            width: calc(100% - 24px);
                            margin-right: unset;
                            margin-left: 24px;
                            clip-path: polygon(62px 0%, 100% 0%, 100% 100%, 0% 100%);
                        }
                    }

                }

                .content {
                    order: 0;

                    @include breakpoint('mobile') {
                        order: 1;
                    }
                }
            }

            &.full {
                background-color: white;

                .content {
                    width: 100%;

                    @include breakpoint('mobile') {
                        width: calc(100% - 80px);
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                width: calc(52% - 48px);
                padding: 24px 24px;
                justify-content: center;
                text-align: end;

                @include breakpoint('mobile') {
                    width: calc(100% - 48px);
                }

                .title {
                    text-align: start;
                    line-height: 1.3em;
                    margin: 0 0 10px 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .content-text {
                    color: $timberGreen;
                    text-align: start;
                    margin-bottom: 24px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                }

                .link {
                    border: 2px solid $timberGreen; // BUTTON FARBE
                    color: $timberGreen; // FRONT FARBE
                    padding: 10px 30px;
                    align-self: flex-start;
                    cursor: pointer;

                    a {
                        color: inherit;
                    }
                }

                h3.title {
                    color: $timberGreen;
                    text-align: start;

                    @include breakpoint('mobile') {
                        //font-size: 32px !important;
                        -webkit-line-clamp: unset !important;
                        -webkit-box-orient: unset !important;
                    }
                }
            }
        }
    }




}
</style>
